<template>
    <div v-if="check4surCharge()">
        <div>
            <div>pro ÜN für {{minPersons}} Personen</div>
        </div>
    </div>
    <div v-else>
        <div>pro ÜN für {{beds}} Personen</div>
    </div>

</template>

<script>
    import V from 'voUtils/V.js';
    import DateEx from 'voUtils/DateEx.js';

    export default {
    	voVueComponent: 'person-surcharge',

    	data: function() {
    		return {
    			minPersons: null,
    		};
    	},

    	props: {
    		pricelist: Array,
    		beds: [Number, String]
    	},

    	methods: {
    		check4surCharge: function() {

    			var minPersons = null;
    			for (var i = 0; i < this.pricelist.length; i++) {
    				var entry = this.pricelist[i];
    				// Überprüfe, ob der Eintrag vom Typ "RENT" ist und surcharge auf true gesetzt ist
    				if (entry.type === "RENT" && entry.surcharge === true) {
    					// Wenn ja, dann lese condition.minPersons aus
    					if (entry.condition && entry.condition.minPersons) {
    						minPersons = entry.condition.minPersons;
    						// Breche die Schleife ab, da wir den gesuchten Eintrag gefunden haben
    						break;
    					}
    				}
    			}

    			// Überprüfe, ob ein Eintrag gefunden wurde
    			if (minPersons !== null) {
    				//console.log("Der Mindestpersonenwert für den gesuchten Eintrag beträgt: " + minPersons);
    				this.minPersons = minPersons - 1;
    				return true;
    			} else {
    				return false
    			}
    		}
    	}
    };
</script>