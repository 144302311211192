var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.check4surCharge()
    ? _c("div", [
        _c("div", [
          _c("div", [
            _vm._v("pro ÜN für " + _vm._s(_vm.minPersons) + " Personen")
          ])
        ])
      ])
    : _c("div", [
        _c("div", [_vm._v("pro ÜN für " + _vm._s(_vm.beds) + " Personen")])
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }